import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import LinkArrow from "../icons/LinkArrow";

const Quote: React.FC = () => {
  return (
    <div className="my-16 sm:my-32 relative max-w-screen-xl w-full mx-auto px-5">
      <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center z-10">
        <StaticImage
          src="../../images/blobs/blob-1.png"
          alt="blob"
          placeholder="blurred"
          height={400}
        />
      </div>

      <div className="absolute top-[-20px] left-[3em] sm:left-[5em] z-[15]">
        <StaticImage
          src="../../images/icons/about-quote.png"
          alt="blob"
          placeholder="blurred"
          height={60}
        />
      </div>

      <div className="relative z-20">
        <div
          className="text-lg sm:text-4xl text-mca-grey font-bold leading-relaxed"
          style={{ textIndent: "2.3em" }}
        >
          Misinformation is eroding trust in media and institutions, creating
          unrest among different sections and communities of society; putting
          law, order, and public safety at risk, and is also impacting the
          health of individuals across the country. This problem cannot be
          solved by any single person or organization alone. There needs to be a
          collaborative effort addressing India’s unique predicament to create a
          more enlightened and aware digital society. This is where the MCA
          comes in.
        </div>
        <div className="flex flex-col gap-y-3 sm:flex-row justify-center my-5">
          {[
            {
              href: "/governing-charter",
              label: "Read the full governing charter",
            },
          ].map(({ href, label }, i) => (
            <a
              className="flex items-center gap-x-1 text-sm sm:text-lg text-mca-grey uppercase font-bold hover:bg-gray-100 bg-opacity-60 transition px-3 py-1 rounded"
              href={href}
              key={i}
            >
              <span>{label}</span>
              <LinkArrow className="h-5 w-5 text-mca-grey" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Quote;
