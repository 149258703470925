import React from "react";

import governingCouncil from "../../data/about/governing-council";
import TwitterIcon from "../../images/icons/twitter.png";
import LinkedinIcon from "../../images/icons/linkedin.png";

const GoverningCouncil: React.FC<{
  council: { [key: string]: string }[];
}> = ({ council }) => {
  return (
    <div className="relative my-20 max-w-screen-xl w-full mx-auto px-5">
      <div className="relative z-20">
        <div className="text-4xl text-mca-grey font-bold text-center">
          <span className="text-mca-green">Governing</span> Council
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-12 my-10 px-0 sm:px-8">
          {council.map(
            ({ avatar: image, name, designation, twitter, linkedin }, i) => (
              <div
                className="flex flex-col items-center justify-between gap-y-6 border-2 border-mca-green rounded-2xl p-5"
                key={i}
              >
                <img
                  src={image}
                  alt={name}
                  className="w-1/2 h-auto rounded-full"
                />
                <div>
                  <div className="text-2xl font-bold text-mca-grey text-center">
                    {name}
                  </div>
                  <div className="text-lg font-bold text-mca-green text-center">
                    {designation}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div>
                    {/*<div className="px-6 py-1 text-md uppercase text-white font-bold bg-mca-green rounded-lg">
                      About
                      </div>*/}
                  </div>
                  <div className="flex gap-x-4 items-center">
                    <a href={twitter} target="_blank">
                      <img
                        src={TwitterIcon}
                        alt="Twitter"
                        className="h-6 w-auto"
                      />
                    </a>
                    <a href={linkedin} target="_blank">
                      <img
                        src={LinkedinIcon}
                        alt="Linkedin"
                        className="h-6 w-auto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default GoverningCouncil;
