import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function () {
  return (
    <div className="flex flex-col-reverse gap-y-8 sm:gap-y-0 sm:grid grid-cols-2 p-5 max-w-screen-xl w-full py-0 sm:py-10 mx-auto">
      <div className="flex flex-col items-center justify-center">
        <div className="w-full">
          <div className="text-4xl sm:text-6xl font-extrabold text-mca-grey">
            About Us
          </div>
          <div className="text-2xl sm:text-3xl font-bold text-mca-grey-2 my-3 sm:my-5">
            The MCA is a cross industry collaborative effort set up with the
            objective of combatting and limiting the spread of misinformation
            through targeted interventions and activities.
          </div>
          <div className="flex gap-x-3 sm:gap-x-6">
            <a
              href="/get-involved"
              className="text-md sm:text-xl font-bold uppercase sm:px-8 px-4 sm:py-4 py-2 bg-mca-green text-mca-off-white rounded-full"
            >
              Join Us
            </a>
            <a
              href="/blogs"
              className="text-md sm:text-xl font-bold uppercase sm:px-8 px-4 sm:py-4 py-2 border border-mca-pink text-mca-pink rounded-full"
            >
              Read our blog
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <StaticImage
          src="../../images/about-landing-image.png"
          alt="Misinformation Combat Alliance"
          placeholder="blurred"
          className="w-auto"
          height={500}
        />
      </div>
    </div>
  );
}
