import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  Landing,
  Quote,
  GoverningCouncil,
  Members,
  Join,
  Donors,
} from "../components/About";
import { graphql } from "gatsby";

const AboutPage = ({ data }) => {
  return (
    <div>
      <Navbar />
      <Landing />
      <Quote />
      <GoverningCouncil council={data.about.council} />
      <Members members={data.about.members} />
      <Join />
      {/*<Donors donors={data.about.donors} />*/}
      <Footer />
    </div>
  );
};

export const query = graphql`
  query AboutPageQuery {
    about {
      members {
        avatar
        name
      }
      council {
        avatar
        designation
        linkedin
        name
        twitter
      }
    }
  }
`;

export default AboutPage;
